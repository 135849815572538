<template>
    <div class="card-table-header">
        <ul class="request__ul">
            <li @click.prevent="setActive(sort.action)" v-for="(sort, index) in sortLabels" :key="index" class="request__li" :class="[mode ? 'request__liday' : 'request__linight',{'request__active': isActive(sort.action)}]">
                <a>
                    <p class="mew-style-khan">{{ sort.title }}</p>
                    <span>{{ sort.count }}</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'filterTable',
    data: () => ({
        active: 'newDeal',
        sortLabels: [
            {
                title: 'Новые заказы',
                count: '4',
                action: 'newDeal',
            },
            {
                title: 'Активные заявки',
                count: '14',
                action: 'activeRequest',
            },
            {
                title: 'Упаковывается',
                count: '7',
                action: 'packed',
            },
            {
                title: 'Груз отправлен',
                count: '9',
                action: 'send',
            },
            {
                title: 'Доставлено в склад',
                count: '10',
                action: 'arrivedWare',
            }
        ],
    }),
    computed: {
        ...mapGetters({
            mode: "MODE"
        }),
    },
    methods: {
        isActive (menuItem) {
            return this.active === menuItem
        },
        setActive (menuItem) {
            this.active = menuItem
        },
    }
}
</script>

<style>
.mew-style-khan{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 99%;
}
</style>

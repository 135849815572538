<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">Заявки</div>
              <div class="block-sarche">
                <div class="header__search ml-4">
                    <el-select :class="mode ? 'input__day' : 'input__night'" сlass="border__unique" v-model="value" size="small" filterable placeholder="Направления">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-date-picker
                        v-model="value1"
                        size="small"
                        :class="mode ? 'input__day' : 'input__night'"
                        type="date"
                        class="mr-3 ml-4"
                        placeholder="От">
                    </el-date-picker>
                    <el-date-picker
                        v-model="value1"
                        size="small"
                        :class="mode ? 'input__day' : 'input__night'"
                        type="date"
                        placeholder="До">
                    </el-date-picker>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            
            <el-button size="small" class="btu-color" @click="createModal = true">
              {{ $t('message.create') }}
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
              <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(column, index) in columns"
                  :key="index"
                >
                  <el-checkbox
                    :checked="column.show"
                    @change="column.show = !column.show"
                    >{{ column.title }}</el-checkbox
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <filter-table />
      <!-- end ifon-add-title-block -->
        <div class="card-table-header table-crm-smart custom__scroll">
            <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered layout__initial">
              <thead>
                  <tr>
                      <th class="w50p" v-if="columns.id.show">
                          {{ columns.id.title }}
                      </th>

                      <th v-if="columns.user.show">
                          {{ $t('message.responsible') }}
                      </th>

                      <th v-if="columns.delivery_deal_id.show">
                          {{  columns.delivery_deal_id.title }}
                      </th>
                      
                      <th v-if="columns.client.show">
                          {{ columns.client.title }}
                      </th>

                      <th v-if="columns.phone.show">
                          {{ columns.phone.title }}
                      </th>

                      <th v-if="columns.email.show">
                          {{ columns.email.title }}
                      </th>

                      <th v-if="columns.from_filial.show">
                          {{ columns.from_filial.title }}
                      </th>

                      <th v-if="columns.to_filial.show">
                          {{ columns.to_filial.title }}
                      </th>

                      <th v-if="columns.products.show">
                          {{ columns.products.title }}
                      </th>

                      <th v-if="columns.begin_date.show">
                          {{ columns.begin_date.title }}
                      </th>

                      <th v-if="columns.end_date.show">
                          {{ columns.end_date.title }}
                      </th>

                      <th v-if="columns.created_at.show">
                          {{ columns.created_at.title }}
                      </th>

                      <th v-if="columns.updated_at.show">
                          {{ columns.updated_at.title }}
                      </th>

                      <th v-if="columns.settings.show">
                          {{ columns.settings.title }}
                      </th>
                  </tr>

                  <tr class="filter_sorche">
                      <th v-if="columns.id.show">
                          <el-input
                              clearable
                              size="mini"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.id"
                              :placeholder="columns.id.title"
                              class="id_input"
                          ></el-input>
                      </th>

                      <th v-if="columns.user.show">
                          <crm-input
                              :placeholder="$t('message.responsible')"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.user"
                          ></crm-input>
                      </th>

                      <th v-if="columns.delivery_deal_id.show">
                          <el-input
                              clearable
                              size="mini"
                              disabled
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.delivery_deal_id"
                              :placeholder="columns.delivery_deal_id.title"
                              class="id_input"
                          ></el-input>
                      </th>

                      <th v-if="columns.client.show">
                          <crm-input
                              :placeholder="columns.client.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.client"
                          ></crm-input>
                      </th>
                      <th v-if="columns.phone.show">
                          <crm-input
                              :placeholder="columns.phone.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.phone"
                          ></crm-input>
                      </th>
                      <th v-if="columns.email.show">
                          <crm-input
                              :placeholder="columns.email.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.email"
                          ></crm-input>
                      </th>
                      <th v-if="columns.from_filial.show">
                          <crm-input
                              :placeholder="columns.from_filial.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.from_filial"
                          ></crm-input>
                      </th>
                      <th v-if="columns.to_filial.show">
                          <crm-input
                              :placeholder="columns.to_filial.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.to_filial"
                          ></crm-input>
                      </th>
                      <th v-if="columns.products.show">
                          <crm-input
                              disabled
                              :placeholder="columns.products.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.products"
                          ></crm-input>
                      </th>

                     <th v-if="columns.begin_date.show">
                          <crm-date-picker
                              :placeholder="columns.begin_date.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.begin_date"
                          ></crm-date-picker>
                      </th>

                      <th v-if="columns.end_date.show">
                          <crm-date-picker
                              :placeholder="columns.end_date.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.end_date"
                          ></crm-date-picker>
                      </th>
                      
                      
                      <th v-if="columns.created_at.show">
                          <crm-date-picker
                              :placeholder="columns.created_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.created_at"
                          ></crm-date-picker>
                      </th>

                      <th v-if="columns.updated_at.show">
                          <crm-date-picker
                              :placeholder="columns.updated_at.title"
                              :class="mode ? 'filter__day' : 'filter__night'"
                              v-model="filterForm.updated_at"
                          ></crm-date-picker>
                      </th>

                      <th
                          class="settinW"
                          v-if="columns.settings.show"
                      ></th>
                  </tr>
              </thead>
              <transition-group name="flip-list" tag="tbody">
                  <tr
                      v-for="deal in list"
                      :key="deal.id"
                      class="cursor-pointer"
                  >
                      <td v-if="columns.id.show">
                          {{ deal.id }}
                      </td>

                      <td v-if="columns.user.show">
                          {{ deal.user ? deal.user.name : '' }}
                      </td>

                      <td v-if="columns.delivery_deal_id.show">
                          {{ deal.delivery_deal_id }}
                      </td>
                      <td v-if="columns.client.show">
                          {{ deal.client ? deal.client.name : '' }}
                      </td>

                      <td v-if="columns.phone.show">
                          {{ deal.phone }}
                      </td>

                      <td v-if="columns.email.show">
                          {{ deal.email }}
                      </td>

                      <td v-if="columns.from_filial.show">
                          {{deal.from_filial ? deal.from_filial.name : '' }}
                      </td>

                      <td v-if="columns.to_filial.show">
                          {{deal.to_filial ? deal.to_filial.name : '' }}
                      </td>

                      <td v-if="columns.products.show">
                          <span @click="showItems(deal)" style="cursor: pointer; color: #0d67c9;">
                              {{ $t('message.show') }} <span class="el-icon-arrow-right"></span>
                          </span>
                      </td>

                      <td v-if="columns.begin_date.show">
                          {{ deal.begin_date }}
                      </td>

                      <td v-if="columns.end_date.show">
                          {{ deal.end_date }}
                      </td>

                      <td v-if="columns.created_at.show">
                          {{ deal.created_at }}
                      </td>

                      <td v-if="columns.updated_at.show">
                          {{ deal.updated_at }}
                      </td>

                      <td
                          v-if="columns.settings.show"
                          class="settings-td"
                      >
                          <crm-settings
                              :name="$options.name"
                              :model="deal"
                              :actions="actions"
                              :permissionShow="'deals.update'"
                              :permissionDestroy="'deals.delete'"
                              @edit="edit"
                              @delete="destroy"
                          ></crm-settings>
                      </td>
                  </tr>
              </transition-group>

            </table>
            <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogItemsVisible" width="60%" @closed="empty()" >
                <div v-loading="loadingData">
                  <el-table :data="dealProducts">
                    <el-table-column prop="name" :label="$t('message.name')">
                        <template slot-scope="scope">
                          {{ scope.row.product ? scope.row.product.name : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="quantity" :label="$t('message.quantity')">
                        
                    </el-table-column>
                    <el-table-column prop="measurement" :label="$t('message.measurement')">
                         <template slot-scope="scope">
                          {{ scope.row.product ? (scope.row.product.measurement ? scope.row.product.measurement.name : '') : '' }}
                        </template>
                    </el-table-column>
                   <el-table-column prop="weight" :label="$t('message.weight_in_kg')">
                        
                    </el-table-column>
                    <el-table-column prop="price" :label="$t('message.price')">
                        <template slot-scope="scope">
                          {{ scope.row.price | formatNumber(1) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="currency" :label="$t('message.currency')">
                        <template slot-scope="scope">
                          {{ (scope.row.currency ? scope.row.currency.code : '')}}
                        </template>
                    </el-table-column>
                  </el-table>
                </div>
            </el-dialog>
            <div class="my___pagination">
              <crm-pagination
                  :class="mode ? 'pagination__day' : 'pagination__night'"
                  @c-change="updatePagination"
                  :pagination="pagination"
              ></crm-pagination>
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
               :with-header="false"  :visible.sync="createModal" size="90%" >
                <div>
                    <crm-create> </crm-create>
                </div>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import filterTable from "./components/filterTable.vue";
import list from "@/utils/mixins/list";

import {mapGetters, mapActions} from 'vuex'

export default {
    name: "deal",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        filterTable
    },

    data() {
        return {
            createModal: false,
            dialogItemsVisible: false,
            loadingData: false,
            options: [{
                value: 'Турция - Узбекистан',
                label: 'Турция - Узбекистан'
                }],
            value: '',
            value1: '',
            activeType: "warehouse",
            form: {},
            sarcheBlocks: "",
            filterForm: {
                id: "",
                customer: "",
                paymentType:"",
                typePayment: "",
              
                time: "",
                sum: "",
                currency: "",
                created_at: "",
                updated_at: "",
            },
        };
    },
    computed: {
        ...mapGetters({
            dealProducts: "dealProducts/dealProducts",
            list: "deals/list",
            columns: "deals/columns",
            pagination: "deals/pagination",
            filter: "deals/filter",
            sort: "deals/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
      ...mapActions({
          updateList: "deals/index",
          setPagination: "deals/setPagination",
          updateSort: "deals/updateSort",
          updateFilter: "deals/updateFilter",
          updateColumn: "deals/updateColumn",
          updatePagination: "deals/updatePagination",
          show: "deals/show",
          empty: "dealProducts/empty",
          delete: "deals/destroy",
          updateDealProducts: "dealProducts/show",
          refreshData: "deals/refreshData",
      }),
      handleClick(type) {
      this.activeType = type;
          if (this.genderstatistic) {
              this.changeStatistics();
          }
      },
      showItems(model) {
          this.dialogItemsVisible = true;
          this.loadingData = true;
          this.updateDealProducts({id: model.id})
            .then((res) => {
              this.loadingData = false;
            })
            .catch((err) => {
              this.loadingData = false;
            });
      },
    },
    
};
</script>
<style>
    .space__between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .space__between span {
        cursor: pointer;
        font-size: 18px;
    }
</style>
